import storage from '../../storage';

const plpThankYou = () => {
  let globalSessionData = {};
  globalSessionData = storage('local', 'get', 'formData');
  const globObj = JSON.parse(globalSessionData);
  if (globObj) {
    const plp = storage('session', 'get', 'plp');
    const prepop = {
      address: globObj.address,
      city: globObj.city,
      city2: globObj.city,
      creditRating: globObj.credit_rating,
      creditType: globObj.credit_rating,
      email: globObj.email,
      firstName: globObj.first_name,
      fName: globObj.first_name,
      lastName: globObj.last_name,
      lName: globObj.last_name,
      homePhone: globObj.phone_primary,
      loanPurpose: globObj.loan_purpose,
      phone: globObj.phone_primary,
      phoneNumber: globObj.phone_primary,
      postalCode: globObj.zipcode,
      zip: globObj.zipcode,
      zipCode: globObj.zipcode,
      state: globObj.state,
      street: globObj.address,
    };

    const combineParams = () => {
      const keys = Object.keys(prepop);
      let urlParam = '';
      keys.forEach((key, index) => {
        if (key) {
          if (index !== keys.length - 1) {
            urlParam += `${key}=${prepop[key]}&`;
          } else {
            urlParam += `${key}=${prepop[key]}`;
          }
        }
      });
      return urlParam.replace(/ /g, '');
    };

    const append = combineParams();

    setTimeout(() => {
      window.location.href = `${plp}&${append}`;
    }, 1500);
  } else {
    window.location.href = 'thankyou/thankyouGCR/';
  }
};

export default plpThankYou;
