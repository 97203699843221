import React, { useEffect } from 'react';
import styled from 'styled-components';
import plpThankYou from '../../utils/forms/thankyou/plpThankYou';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const LoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  h1 {
    font-weight: 400;
  }
`;

const PlThankyou = () => {
  useEffect(() => {
    plpThankYou();
  }, []);
  return (
    <LoadingSpinner>
      <h1>We are now connecting you with our premier partner…</h1>
      <img
        src="https://s3.amazonaws.com/sc-dba.com/qkn-ty/images/loading.gif"
        alt="loading spinner"
      />
    </LoadingSpinner>
  );
};

export default withThankyouWrapper(PlThankyou);
